<template>
  <s-crud
    title="Detalle Categorías"
    :config="config"
    @save="save($event)"

    add
    remove
    :filter="filter"
    ref="crud"
    height="auto"
  >
    <template scope="props">
      <v-container disabled=props.item.C>
        <v-row justify="center">
          <v-col cols="12" class="s-col-form" sm="8" md="8" lg="12">
            <s-select-definition label="Calibre" :def="1266"  v-model="props.item.TypeCaliber"></s-select-definition>
            <!-- <s-text
              v-model="props.item.DctgDescription"
              label="Descripción"
              autofocus
            >
            </s-text> -->
          </v-col>
          <v-col cols="12" class="s-col-form"  lg="6">
            <s-date
              label="Fecha Inicio"
              v-model="props.item.DctgDateStar"
            ></s-date>
          </v-col>
          <v-col cols="12" class="s-col-form"  lg="6">
            <s-date label="Fecha Fin" v-model="props.item.DctgDateEnd"></s-date>
          </v-col>
        </v-row>
        <v-row justify="center">
          <v-col cols="12" class="s-col-form" sm="8" md="8" lg="8">
            <s-text v-model="props.item.DctgObservation" label="Observación">
            </s-text>
          </v-col>
          <v-col cols="12" class="s-col-form" sm="4" md="4" lg="4">
            <s-select-definition
              v-model="props.item.DctgStatus"
              :def="1221"
              label="Estado"
            ></s-select-definition>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <template v-slot:Estado="{ row }">
      <v-chip
        style="margin: 0px"
        x-small
        :color="row.Estado == 'Inactivo' ? 'error' : 'success'"
      >
        {{ row.Estado }}
      </v-chip>
    </template>
  </s-crud>
</template>


<script>
import TasPaymentCategoryDetailServices from "@/services/Technicalassistance/TasPaymentCategoryDetailService";
import SSelectDefinition from '../../../components/Utils/SSelectDefinition.vue';

export default {
  name: "RequirementServiceTasPaymentCategoryService",
  components: {SSelectDefinition},
  props: {
    CtgID: { type: Number, default: 0 },
    parentID: { type: Number, default: 0 },
    addNoFunction: { type: Boolean, default: false },
  },

  data() {
    ObjCaliber:{}
    return {
      dialogEdit: false,
       filter:{ CtgID:0 }
    };
  },

  computed: {
    config() {
      return {
        model: {
          //RETORNAR PROPIEDAD COMPUTADA
          DctgID: "ID",
          CtgID: "int",
          DctgDescription: "string",
          DctgObservation: "string",
          DctgStatus: "status",
          Estado: "string",
          DctgDateStar:'date',
          DctgDateEnd:'date'
          //
        },
        service: TasPaymentCategoryDetailServices,
        headers: this.$fun.getSecurity().IsLevelAdmin
          ? [
              //Vista de tabla para admin
              { text: "ID", value: "DctgID", sortable: false },
              { text: "Descripción", value: "DctgDescription", sortable: false, },
              { text: "Fecha Inicio", value: "DctgDateStar", sortable: false },
              { text: "Fecha Fin", value: "DctgDateEnd", sortable: false },
              { text: "Estado", value: "Estado", sortable: false },
            ]
          : [
              // CABECERA EN CASO NO SE LOGEE COMO ADMIN
              { text: "ID", value: "DctgID", sortable: false },
              { text: "Descripción", value: "DctgDescription", sortable: false,},
              { text: "Categoria", value: "CtgDescription", sortable: false },
              { text: "Fecha Inicio", value: "DctgDateStar", sortable: false },
              { text: "Fecha Fin", value: "DctgDateEnd", sortable: false },
              { text: "Estado", value: "Estado", sortable: false },
            ],
      };
    },

    isAdmin() {
      this.$fun.getSecurity().IsLevelAdmin;
    },
  },

  methods: {
    Initialize() {
      //DedID: this.DedID -- base de datos: campo actual
      this.filter = { CtgID: this.CtgID };
    },

    edit(item) {
      this.dialogEdit = true;
    },

    rowSelected(rows) {
      if (rows.length > 0) {
        this.$emit("rowSelected", rows);
      }
    },

    save(item) {

      // console.log(item);
      item.CtgID = this.CtgID;

      if (this.$fun.getSecurity().IsLevelAdmin) {
        //VALIDACIONES
      
        if (item.CtgID == 0) {
          this.$fun.alert("Seleccione la Categoria", "warning");
          return;
        }

        
        item.SecStatus = 1;
        item.save();
      } else {
      
        if (item.CtgID == 0) {
          this.$fun.alert("Seleccione la Categoria", "warning");
          return;
        }


        item.SecStatus = 1;
        item.TypeCaliber = this.ObjCaliber.DedValue;
        
        item.DctgDescription = this.ObjCaliber.DedDescription;
        item.save();
      }
    },
  },
  watch: {
    // este DedID es el que esta en variety, vista general en DedID  ="dedID"
    CtgID() {
      this.Initialize();
    },
  },
  created() {
    this.filter = { CtgID: this.CtgID };
  },
};
</script>
