<template>
  <div>
    <v-row>
     
        <v-col lg="12" class="pb-2">
        <payment-category :isAdmin="true" @rowSelected="rowSelected1($event)" :DedID="dedID" >
        </payment-category> 
             
      </v-col>
       <v-col class="pt-0" cols="12"  v-if='ctgID!=0'>
        <payment-category-detail :isAdmin="true" :CtgID="ctgID"  >
           </payment-category-detail>
           </v-col>
          
    </v-row>
  </div>
</template>

<script>
import Definitions from "@/views/TechnicalAssistance/Categorization/Definitions";
import PaymentCategory from "@/views/TechnicalAssistance/Categorization/PaymentCategory";
import PaymentCategoryDetail from "@/views/TechnicalAssistance/Categorization/PaymentCategoryDetail";
export default {
  components: {
        Definitions,PaymentCategory,PaymentCategoryDetail
     },

   data() {
    return {
      parentID: 0,
      dedID:0,
     ctgID:0,
    };
  },

  methods: {
    rowSelected1(rows) {
      if(rows.length > 0)
     this.ctgID=rows[0].CtgID;
      else this.ctgID=0;
    },
   
  },
};
</script>
