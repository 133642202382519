import Service from "../Service";
const resource = "paymentcategory/";

export default {
  save(prd, requestID) {
    return Service.post(resource + "save", prd, { params: { requestID: requestID }, });
  },

  pagination(parameters, requestID) {
    return Service.post(resource + "pagination", parameters, {
      params: { requestID: requestID },
    });
  },
  //pdf
};
